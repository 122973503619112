import { useState } from "react";
import { t } from "./shared/utilts";

//pages
import {
  Home,
  UserInformationPage,
  NourishmentPage,
  OfferPage,
  FoodOptionsPage,
  DailyLifePage,
  OrderBookPage
} from "./Pages";

// components
import { NavBar, AlertMessage } from "./Components";

//custom
import { LoadCsvData } from "./Components/LoadCsvData";
import { useAppSelector } from "../src/redux/hooks";

console.log("App version 1.5");

const App: React.FC<{}> = () => {
  // Step indicator
  const [currentStep, setCurrentStep] = useState(1);

  const [generalAlert, setGeneralAlert] = useState(false);

  // Redux slices
  const { home, userInformation, nourishment, foodOptions, dailyLife } = useAppSelector(
    (state) => state
  );

  const sectionFields: Array<boolean> = [
    home.allFieldsAreCompleted,
    userInformation.allFieldsAreCompleted,
    nourishment.allFieldsAreCompleted,
    true,
    foodOptions.allFieldsAreCompleted,
    dailyLife.allFieldsAreCompleted,
    true
  ];

  // TODO: Create a range number type until 6 for step
  function updateStep(step: number) {
    setGeneralAlert(false);
    const allFiledsAreCompleted = sectionFields.slice(0, step - 1).every((section) => section);

    allFiledsAreCompleted ? setCurrentStep(step) : setGeneralAlert(true);

    setTimeout(() => {
      setGeneralAlert(false);
    }, 4000);
  }

  const Components: Array<JSX.Element> = [
    <Home updateStep={updateStep} />,
    <UserInformationPage
      currentStep={currentStep}
      updateStep={updateStep}
      triggerAlert={generalAlert}
    />,
    <NourishmentPage
      currentStep={currentStep}
      updateStep={updateStep}
      triggerAlert={generalAlert}
    />,
    <OfferPage currentStep={currentStep} updateStep={updateStep} />,
    <FoodOptionsPage
      currentStep={currentStep}
      updateStep={updateStep}
      triggerAlert={generalAlert}
    />,
    <DailyLifePage currentStep={currentStep} updateStep={updateStep} triggerAlert={generalAlert} />,
    <OrderBookPage />
  ];

  return (
    <div className="App">
      <LoadCsvData path={"/zutaten.csv"} />

      {/* displaying Navbar for all pages */}
      <NavBar currentStep={currentStep} updateStep={updateStep} />

      {/* Conditioning pages to display according to step numbers. */}
      <AlertMessage generalAlert={generalAlert} mainErrorText={t("Alert.mainErrorText")} />

      {Components[currentStep - 1]}
    </div>
  );
};

export default App;
