import { createSlice } from "@reduxjs/toolkit";

export const orderBookSlice = createSlice({
  name: "orderBookSlice",
  initialState: {
    firstName: undefined,
    lastName: undefined
  },
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    }
  }
});
// Export Actions
export const { setFirstName, setLastName } = orderBookSlice.actions;
// Export Reducer
export const { reducer } = orderBookSlice;
