import { lazy } from "react";

const Home = lazy(() => import("./Home/Home"));
const UserInformationPage = lazy(() => import("./UserInformation/UserInformationPage"));
const OfferPage = lazy(() => import("./Offer/OfferPage"));

const NourishmentPage = lazy(() => import("./Nourishment/NourishmentPage"));
const FoodOptionsPage = lazy(() => import("./FoodOptions/FoodOptionsPage"));
const DailyLifePage = lazy(() => import("./DailyLife/DailyLifePage"));
const OrderBookPage = lazy(() => import("./OrderBook/OrderBookPage"));

export {
  Home,
  UserInformationPage,
  NourishmentPage,
  OfferPage,
  FoodOptionsPage,
  DailyLifePage,
  OrderBookPage
};
