import { createTheme } from "@mui/material/styles";

const breakpoints = {
  xs: 400,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536
};
export const theme = createTheme({
  palette: {
    primary: {
      main: "#10ADC4"
    },
    secondary: {
      main: "#13E09B"
    },
    text: {
      primary: "#606060"
    }
  },
  typography: {
    fontFamily: '"Helvetica Neue", sans-serif',
    h1: {
      fontSize: "46px",
      fontWeight: "bold",
      color: "#10ADC4",
      letterSpacing: "0px",
      maxWidth: "90%",
      margin: "auto",
      [`@media screen and (max-width: ${breakpoints.lg}px)`]: {
        fontSize: "38px"
      },
      [`@media screen and (max-width: ${breakpoints.sm}px)`]: {
        fontSize: "30px"
      },
      [`@media screen and (max-width: ${breakpoints.xs}px)`]: {
        fontSize: "28px"
      }
    },
    h2: {
      fontSize: "32px",
      fontWeight: "bold",
      color: "#10ADC4",
      letterSpacing: "0px",
      maxWidth: "90%",
      margin: "auto",
      [`@media screen and (max-width: ${breakpoints.lg}px)`]: {
        fontSize: "28px"
      },
      [`@media screen and (max-width: ${breakpoints.sm}px)`]: {
        fontSize: "24px"
      },
      [`@media screen and (max-width: ${breakpoints.xs}px)`]: {
        fontSize: "22px"
      }
    },
    h3: {
      fontSize: "28px",
      fontWeight: "bold",
      color: "#10ADC4",
      letterSpacing: "0px",
      maxWidth: "90%",
      margin: "auto",
      [`@media screen and (max-width: ${breakpoints.lg}px)`]: {
        fontSize: "24px"
      },
      [`@media screen and (max-width: ${breakpoints.sm}px)`]: {
        fontSize: "20px"
      },
      [`@media screen and (max-width: ${breakpoints.xs}px)`]: {
        fontSize: "18px"
      }
    },
    h4: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#10ADC4",
      letterSpacing: "0px",
      maxWidth: "90%",
      margin: "auto",
      [`@media screen and (max-width: ${breakpoints.lg}px)`]: {
        fontSize: "20px"
      },
      [`@media screen and (max-width: ${breakpoints.sm}px)`]: {
        fontSize: "16px"
      },
      [`@media screen and (max-width: ${breakpoints.xs}px)`]: {
        fontSize: "14px"
      }
    },
    h5: {
      fontSize: "22px",
      fontWeight: "medium",
      color: "#606060",
      letterSpacing: "0px",
      maxWidth: "90%",
      margin: "auto",
      [`@media screen and (max-width: ${breakpoints.lg}px)`]: {
        fontSize: "18px"
      },
      [`@media screen and (max-width: ${breakpoints.sm}px)`]: {
        fontSize: "14px"
      },
      [`@media screen and (max-width: ${breakpoints.xs}px)`]: {
        fontSize: "12px"
      }
    },
    h6: {
      fontSize: "18px",
      fontWeight: "medium",
      letterSpacing: "0px",
      maxWidth: "90%",
      margin: "auto",
      [`@media screen and (max-width: ${breakpoints.lg}px)`]: {
        fontSize: "16px"
      },
      [`@media screen and (max-width: ${breakpoints.sm}px)`]: {
        fontSize: "14px"
      },
      [`@media screen and (max-width: ${breakpoints.xs}px)`]: {
        fontSize: "12px"
      }
    },
    body1: {
      fontSize: "22px",
      fontWeight: "medium",
      color: "#606060",
      letterSpacing: "0px",
      maxWidth: "90%",
      margin: "auto",
      [`@media screen and (max-width: ${breakpoints.lg}px)`]: {
        fontSize: "18px"
      },
      [`@media screen and (max-width: ${breakpoints.sm}px)`]: {
        fontSize: "14px",
        [`@media screen and (max-width: ${breakpoints.xs}px)`]: {
          fontSize: "12px"
        }
      }
    },
    body2: {
      fontSize: "20px",
      fontWeight: "medium",
      color: "#707070",
      letterSpacing: "0px",
      maxWidth: "90%",
      margin: "auto",
      [`@media screen and (max-width: ${breakpoints.lg}px)`]: {
        fontSize: "16px"
      },
      [`@media screen and (max-width: ${breakpoints.sm}px)`]: {
        fontSize: "12px",
        [`@media screen and (max-width: ${breakpoints.xs}px)`]: {
          fontSize: "10px"
        }
      }
    }
  }
});
